import Badge, { BadgeColor } from '../badge';
import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

export interface ListItemProps {
  main?: string;
  id: string;
  subMainPrimary?: string;
  subMainSecondary?: string;
  info?: string;
  statusClassName20?: string;
  statusClassName?: string;
  statusText?: string;
  href?: string;
}

export const ListItem = (props: ListItemProps) => {
  const navigate = useNavigate();
  return (
    <li
      onClick={() => {
        if (props.href) {
          navigate(props.href);
        }
      }}
      key={props.id}
      className={`flex gap-x-4 py-5 px-4 items-center justify-between hover:bg-gray-50 ${
        props.href ? 'hover:cursor-pointer' : ''
      }`}
    >
      <div className="flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none">
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            {props.main != null ? props.main : <Badge color={BadgeColor.Grey} expectedChars={20} />}
          </p>
          <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p className="mt-1 flex text-xs leading-5 text-gray-500">
              {props.subMainPrimary != null ? (
                props.subMainPrimary
              ) : (
                <Badge color={BadgeColor.Grey} expectedChars={15} />
              )}
            </p>
            {props.subMainSecondary != null && (
              <>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current mt-1">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                  {props.subMainSecondary ? (
                    props.subMainSecondary
                  ) : (
                    <Badge color={BadgeColor.Grey} expectedChars={15} />
                  )}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-x-4 sm:w-1/2 sm:flex-none">
        <div className="hidden sm:block">
          <p className="text-sm leading-6 text-gray-900 min-h-2">
            {props.info != null ? props.info : <Badge color={BadgeColor.Grey} expectedChars={15} />}
          </p>
          <div className="mt-1 flex items-center gap-x-1.5">
            <div
              className={`flex-none rounded-full ${
                props.statusClassName20 != null ? props.statusClassName20 : ''
              } p-1`}
            >
              <div
                className={`h-1.5 w-1.5 rounded-full ${
                  props.statusClassName != null ? props.statusClassName : ''
                }`}
              />
            </div>
            <p className="text-xs leading-5 text-gray-500">
              {props.statusText != null ? (
                props.statusText
              ) : (
                <Badge color={BadgeColor.Grey} expectedChars={12} />
              )}
            </p>
          </div>
        </div>
        {props.href && (
          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400 mr-8" aria-hidden="true" />
        )}
      </div>
    </li>
  );
};
