import React, { useEffect, useState } from 'react';
import {
  useGetTenantsByTenantIdQuery,
  usePatchTenantsByTenantIdMutation,
} from '../../store/mgmtApi';
import SubPageProps from '../props';
import InputPanel from '../../components/inputPanel';

const GeneralSettings = (props: SubPageProps) => {
  const [tenantNameValue, setTenantNameValue] = useState<string | null>(null);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [resetEnabled, setResetEnabled] = useState(false);

  const {
    data: tenantData,
    isError: tenantLoadHasError,
    error: tenantLoadError,
  } = useGetTenantsByTenantIdQuery(props.tenant.id);

  const [patchTenant, { isLoading: patchTenantLoading }] = usePatchTenantsByTenantIdMutation();

  if (tenantLoadHasError) {
    return <p>{`loading tenant failed: ${tenantLoadError}`}</p>;
  }

  const updateTenantName = () => {
    if (tenantNameValue == null) {
      return;
    }
    patchTenant({
      tenantId: props.tenant.id,
      patchTenant: {
        name: tenantNameValue,
      },
    });
  };

  useEffect(() => {
    if (!tenantData) {
      return;
    }
    setTenantNameValue(tenantData.name ? tenantData.name : props.tenant.id);
  }, [tenantData]);

  useEffect(() => {
    const currentTenantName = props.tenant.name ? props.tenant.name : props.tenant.id;
    setSaveEnabled(
      !!tenantNameValue && tenantNameValue.length >= 3 && tenantNameValue != currentTenantName,
    );
    setResetEnabled(tenantNameValue != currentTenantName);
  }, [tenantNameValue, props.tenant.name]);

  return (
    <div className="pl-5">
      <InputPanel
        title="Tenant Name"
        saveDisabled={!saveEnabled}
        text="Set a friendly tenant name so you can easily identify it."
        value={tenantNameValue}
        saveLoading={patchTenantLoading}
        label="Name"
        onChange={(newValue) => {
          setTenantNameValue(newValue);
        }}
        resetAction={() => {
          setTenantNameValue(props.tenant.name ? props.tenant.name : props.tenant.id);
        }}
        identifier="tenant-friendly-name"
        inputType="text"
        reset={resetEnabled}
        saveAction={() => {
          updateTenantName();
        }}
      />
    </div>
  );
};

export default GeneralSettings;
