import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export interface SimpleGridListItem {
  name: string;
  initials?: string;
  href: string;
  subtitle: string;
}
export interface SimpleGridListProps {
  title: string;
  items: SimpleGridListItem[];
}

function generateInitials(name: string): string {
  if (name === '') {
    return '';
  }
  if (name.split(' ').length > 1) {
    return name
      .split(' ')
      .map((word) => word.at(0))
      .join('')
      .toUpperCase();
  }
  if (name.split(/(?=[A-Z])/).length > 1) {
    return name
      .split(/(?=[A-Z])/)
      .map((word) => word.at(0))
      .join('')
      .toUpperCase();
  }
  let initials = '';
  for (let i = 0; i < Math.min(name.length, 3); i++) {
    initials += name.at(i);
  }
  return initials.toUpperCase();
}
export default function SimpleGridList(props: SimpleGridListProps) {
  return (
    <div>
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">{props.title}</h2>
      <ul role="list" className="mt-3 grid grid-cols-2 gap-y-3">
        {props.items.map((item) => (
          <>
            <Link to={item.href} target="_blank">
              <li key={item.name} className="col-span-1 flex shadow-sm rounded-md">
                <div
                  className={
                    'bg-gray-200 flex-shrink-0 flex items-center justify-center w-16 ' +
                    'text-sm font-medium rounded-l-md'
                  }
                >
                  {item.initials ? item.initials : generateInitials(item.name)}
                </div>
                <div
                  className={
                    'flex-1 flex items-center justify-between border-t border-r border-b ' +
                    'border-gray-200 bg-white rounded-r-md truncate'
                  }
                >
                  <div className="flex-1 px-4 py-2 text-sm truncate">
                    <div className="flex justify-between">
                      <p className="text-gray-900 font-medium hover:text-gray-600">{item.name}</p>
                      <ArrowTopRightOnSquareIcon height={20} />
                    </div>
                    <p className="text-gray-500">{item.subtitle}</p>
                  </div>
                </div>
              </li>
            </Link>
            <div className="col-span-1 flex"></div>
          </>
        ))}
      </ul>
    </div>
  );
}
