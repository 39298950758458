import { HorizontalLine } from '../horizontalLine';
import React, { useState } from 'react';
import Input from '../input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ListItem } from './listItem';

interface Action {
  actionFunc: () => void;
  title: string;
}

export interface ItemStatus {
  title: string;
  className: string;
  className20: string;
}
export interface Item {
  main: string;
  subMainPrimary?: string;
  subMainSecondary?: string;
  info?: string;
  href: string;
  status?: ItemStatus;
  id: number;
}
export interface StackedTwoColumnsListProps {
  className?: string;
  mainAction: Action;
  items: Item[] | null;
}

export default function StackedTwoColumnsList(props: StackedTwoColumnsListProps) {
  const { items } = props;
  const [query, setQuery] = useState('');
  const filteredItems =
    items == null
      ? []
      : query === ''
        ? items
        : items.filter((item) => {
          return item.main.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className={`px-4 ${props.className ? props.className : ''}`}>
      <div className="sm:flex sm:items-center">
        <Input
          icon={MagnifyingGlassIcon}
          value={query}
          name={'api-search'}
          htmlId={'api-search'}
          onChange={(val) => setQuery(val)}
          placeholder={'Search'}
          disabled={!items || items.length == 0}
        />
        {props.mainAction && (
          <div className="sm:flex-none ml-auto">
            <button
              onClick={() => {
                props.mainAction.actionFunc();
              }}
              type="button"
              className={
                'inline-flex items-center justify-center rounded-md border ' +
                'border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white ' +
                'shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 ' +
                'focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              }
            >
              {props.mainAction.title}
            </button>
          </div>
        )}
      </div>
      <HorizontalLine />
      <ul
        role="list"
        className="divide-y divide-gray-100 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
      >
        {!items && (
          <>
            <ListItem id={'1'} info="" />
            <ListItem id={'2'} info="" />
          </>
        )}
        {items &&
          items.length > 0 &&
          filteredItems.map((item) => (
            <>
              <ListItem
                id={item.main}
                main={item.main}
                subMainPrimary={item.subMainPrimary}
                subMainSecondary={item.subMainSecondary}
                statusText={item.status?.title}
                statusClassName={item.status?.className}
                statusClassName20={item.status?.className20}
                href={item.href}
                info=""
              />
            </>
          ))}
        {items && items.length > 0 && filteredItems.length == 0 && (
          <>
            <p className="text-gray-700 text-sm">No APIs found for search term '{query}'</p>
          </>
        )}
        {items && items.length == 0 && (
          <>
            <p className="text-gray-700 text-sm">Create your first API now!</p>
          </>
        )}
      </ul>
    </div>
  );
}
