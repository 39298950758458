import { Route, Routes, useParams } from 'react-router-dom';
import {
  useGetAuthPermissionsQuery,
  useGetUsersMeQuery,
  useGetTenantsByTenantIdQuery,
} from '../store/mgmtApi';
import React, { useState } from 'react';
import TenantMgmtShell from './TenantMgmtShell';
import DashboardPage from '../subpages/dashboard/dashboardPage';
import ApisPage from '../subpages/apis/apisPage';
import CustomerExperiencePage from '../subpages/customerExperience/CustomerExperiencePage';
import {
  ChartBarSquareIcon,
  CodeBracketSquareIcon,
  HomeIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import SubLoader from '../subpages/SubLoader';
import SubPageProps from '../subpages/props';
import SettingsPage from '../subpages/settings/SettingsPage';
import { PlaygroundAPICreation } from '../subpages/apis/create/playground';

export interface NavElement {
  element: (props: SubPageProps) => JSX.Element;
  name: string;
  title?: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
  >;
}

const navElements: NavElement[] = [
  {
    element: DashboardPage,
    name: 'Dashboard',
    href: 'dashboard',
    icon: HomeIcon,
  },
  {
    element: ApisPage,
    name: 'APIs',
    href: 'apis',
    icon: CodeBracketSquareIcon,
  },
  {
    element: CustomerExperiencePage,
    name: 'Customer Experience',
    href: 'customer-experience',
    icon: ChartBarSquareIcon,
  },
  {
    element: SettingsPage,
    name: 'Settings',
    title: 'Tenant Settings',
    href: 'settings',
    icon: WrenchScrewdriverIcon,
  },
];

const TenantManagement = () => {
  const { tenant: tenantId } = useParams();
  if (tenantId == undefined) {
    console.error('no tenant set');
    return <p>{`No tenant found`}</p>;
  }

  const {
    data: myUserData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    error: userDataError,
  } = useGetUsersMeQuery();

  const { data: authData, isError, error } = useGetAuthPermissionsQuery();

  const {
    data: tenantData,
    isError: tenantLoadHasError,
    error: tenantLoadError,
  } = useGetTenantsByTenantIdQuery(tenantId);

  const [activeElement, setActiveElement] = useState('Dashboard');
  const [pageTitle, setPageTitle] = useState('Dashboard');

  if (isUserDataLoading || !myUserData) {
    return <p>Loading user profile...</p>;
  }

  if (tenantLoadHasError) {
    console.error(tenantLoadError);
    return <p>{`Error loading tenant: ${JSON.stringify(tenantLoadError)}`}</p>;
  }
  if (isUserDataError) {
    console.error(userDataError);
    return <p>{`Error loading user: ${JSON.stringify(userDataError)}`}</p>;
  }

  if (isError) {
    console.error(error);
    return <p>{`Error loading permissions: ${JSON.stringify(error)}`}</p>;
  }

  const tenantName = tenantData?.name ? tenantData.name : tenantData?.id;
  const permission = authData
    ? authData.permissions.find((permission) => permission.tenantId === tenantId)
    : undefined;
  const role = permission ? permission.role : undefined;
  return (
    <TenantMgmtShell
      tenantId={tenantData?.id}
      userRole={role}
      navElements={navElements}
      username={myUserData.email}
      userId={myUserData.id}
      title={pageTitle}
      activeElement={activeElement}
      initialized={tenantData ? tenantData.name != '' : undefined}
      tenantName={tenantName}
    >
      <Routes>
        {navElements.map((nav) => (
          <Route
            key={`r-${nav.name}`}
            path={`/${nav.href}`}
            element={
              <SubLoader
                key={`sl-${nav.name}`}
                loaded={() => {
                  setActiveElement(nav.name);
                  setPageTitle(nav.title ? nav.title : nav.name);
                }}
              >
                <nav.element
                  tenant={{
                    id: tenantId,
                    name: tenantName,
                  }}
                  user={{
                    id: myUserData.id,
                    mail: myUserData.email,
                    role: role,
                  }}
                  key={`ne-${nav.name}`}
                />
              </SubLoader>
            }
          />
        ))}
        <Route
          path={'playground-apis/create'}
          element={
            <SubLoader
              loaded={() => {
                setActiveElement('APIs');
                setPageTitle('Create Playground API');
              }}
            >
              <PlaygroundAPICreation />
            </SubLoader>
          }
        />
      </Routes>
    </TenantMgmtShell>
  );
};

export default TenantManagement;
