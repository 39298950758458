import React, { useEffect } from 'react';

interface SubPageProps {
  loaded: () => void;
  children: React.ReactElement;
}
const SubLoader = (props: SubPageProps) => {
  useEffect(() => {
    props.loaded();
  }, []);
  return <>{props.children}</>;
};
export default SubLoader;
