import React from 'react';
import { useGetPortalsByTenantIdQuery } from '../../store/mgmtApi';
import SubPageProps from '../props';
import { ArrowTopRightOnSquareIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import Table from '../../components/table';

const link = (url: string) => (
  <a
    target="_blank"
    href={url}
    className={'underline flex items-center text-gray-500 hover:text-gray-900'}
  >
    {url} <ArrowTopRightOnSquareIcon className="ml-2" height={15} />
  </a>
);

const PortalGeneralSettings = (props: SubPageProps) => {
  const { data: portal, isError: portalLoadError } = useGetPortalsByTenantIdQuery(props.tenant.id);
  if (portalLoadError) {
    return <h3>Error loading portal</h3>;
  }
  if (!portal) {
    return <p>Loading portal...</p>;
  }

  const editButton = (
    <button type="button">
      <Cog6ToothIcon height={20} />
    </button>
  );
  return (
    <>
      <Table
        headers={[
          { content: 'Portal Website', expectedValueLength: 10 },
          { content: '', expectedValueLength: 150 },
          { content: editButton, expectedValueLength: 20 },
        ]}
        items={[
          ['Default URL', link(portal.host), ''],
          ['Custom URL', portal.customHost ? link(portal.customHost) : 'Not set', ''],
        ]}
        subTitle={''}
        title={''}
      />
    </>
  );
};

export default PortalGeneralSettings;
