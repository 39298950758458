import React, { useState } from 'react';
import Tabs, { TabProps } from '../../components/tabs';
import { InformationCircleIcon, UsersIcon } from '@heroicons/react/24/outline';
import SubPageProps from '../props';
import PortalGeneralSettings from './PortalGeneralSettings';

const tabs: TabProps[] = [
  {
    name: 'General',
    icon: InformationCircleIcon,
  },
  {
    name: 'Login Experience',
    icon: UsersIcon,
  },
];

const SettingsPage = (props: SubPageProps) => {
  const [activeTabName, setActiveTabName] = useState('General');

  return (
    <>
      <Tabs tabs={tabs} activeTabName={activeTabName} setActiveTabName={setActiveTabName} />
      {activeTabName == 'General' && (
        <PortalGeneralSettings tenant={props.tenant} user={props.user} />
      )}
      {activeTabName == 'Login Experience' && (
        <PortalGeneralSettings tenant={props.tenant} user={props.user} />
      )}
    </>
  );
};

export default SettingsPage;
