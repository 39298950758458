export interface HeadingProps {
  text: string;
  size: string;
}

const Heading = (props: HeadingProps) => {
  switch (props.size) {
    case 'h1':
      return <h1 className="text-5xl font-extrabold dark:text-gray-600">{props.text}</h1>;
    case 'h2':
      return <h2 className="text-4xl font-extrabold dark:text-gray-600">{props.text}</h2>;
    case 'h3':
      return <h3 className="text-3xl font-extrabold dark:text-gray-600">{props.text}</h3>;
    case 'h4':
      return <h4 className="text-2xl font-extrabold dark:text-gray-600">{props.text}</h4>;
    case 'h5':
      return <h5 className="text-xl font-extrabold dark:text-gray-600">{props.text}</h5>;
  }
  console.error('no heading found with size', props.size);
  return <h1 className="text-5xl font-extrabold dark:text-white">{props.text}</h1>;
};

export default Heading;
