import React from 'react';
//import { useDeleteTenantsByTenantIdMutation } from '../../store/mgmtApi';
import SubPageProps from '../props';
import { TrashIcon } from '@heroicons/react/24/outline';

const DangerZoneSettings = (props: SubPageProps) => {
  // const [deleteTenant] = useDeleteTenantsByTenantIdMutation();

  const sendTenantDeletion = () => {
    // deleteTenant(props.tenant.id);
  };

  return (
    <div className="pl-5">
      <button
        type="button"
        onClick={() => {
          sendTenantDeletion();
        }}
        className={
          'inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm ' +
          'font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline ' +
          'focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
        }
      >
        <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Delete Tenant
      </button>
    </div>
  );
};

export default DangerZoneSettings;
