import Badge, { BadgeColor } from '../badge';

export interface InputPanelProps {
  title: string;
  text: string;
  value: string | null;
  label: string;
  onChange: (newValue: string) => void;
  identifier: string;
  inputType: string;
  saveText?: string;
  saveAction: () => void;
  reset?: boolean;
  resetTitle?: string;
  resetAction?: () => void;
  saveDisabled?: boolean;
  saveLoading: boolean;
}

export default function InputPanel(props: InputPanelProps) {
  return (
    <div className="bg-white shadow sm:rounded-lg lg:w-1/2 sm:w-full">
      <div className="px-4 py-5">
        <h4 className="text-base leading-6 font-medium text-gray-900">{props.title}</h4>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{props.text}</p>
        </div>
        <form className="mt-5 sm:flex sm:items-center">
          <div className="w-full sm:max-w-xs">
            <label htmlFor={`input-${props.identifier}`} className="sr-only">
              {props.label}
            </label>
            {props.value && (
              <input
                type={props.inputType}
                name={`input-${props.identifier}`}
                id={`input-${props.identifier}`}
                className={
                  'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block ' +
                  'w-full sm:text-sm border-gray-300 rounded-md'
                }
                value={props.value ? props.value : ''}
                onChange={(event) => {
                  props.onChange(event.target.value);
                }}
              />
            )}
            {!props.value && <Badge color={BadgeColor.Grey} expectedChars={30} />}
          </div>
          <button
            disabled={props.saveDisabled || props.saveLoading}
            onClick={() => {
              props.saveAction();
            }}
            type="button"
            className={
              'inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold ' +
              'text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:opacity-50'
            }
          >
            {props.saveLoading ? '...' : props.saveText ? props.saveText : 'Save'}
          </button>
          {props.reset && (
            <button
              onClick={() => {
                props.resetAction ? props.resetAction() : null;
              }}
              type="reset"
              className={
                'inline-flex w-full justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold ' +
                'text-white shadow-sm hover:bg-gray-400 sm:ml-3 sm:w-auto disabled:opacity-50'
              }
            >
              {props.resetTitle ? props.resetTitle : 'Reset'}
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
