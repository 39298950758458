import { Navigate, useSearchParams } from 'react-router-dom';
import { usePostInvitesAcceptMutation } from '../store/mgmtApi';
import React, { useEffect } from 'react';

export const JoinPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [acceptInvite, { isLoading: isLoading, isSuccess: inviteAccepted }] =
    usePostInvitesAcceptMutation();

  if (token === null) {
    return <p>Invitation token invalid</p>;
  }

  useEffect(() => {
    acceptInvite({
      token: token,
      genericPostRequest: {},
    });
  }, []);

  if (isLoading) {
    return <p>Accept invitation...</p>;
  }

  if (!inviteAccepted) {
    return <p>Invitation could not be accepted</p>;
  }

  return <Navigate replace to="/" />;
};
