import * as React from 'react';

type OnChangeFunc = {
  (value: string): void;
};

interface InputProps {
  htmlId: string;
  name: string;
  title?: string;
  placeholder: string;
  onChange: OnChangeFunc;
  value: string;
  disabled?: boolean;
  icon?: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
  >;
}

const Input = (props: InputProps) => {
  return (
    <div>
      {props.title && (
        <label htmlFor={props.htmlId} className="block text-sm font-medium leading-6 text-gray-900">
          {props.title}
        </label>
      )}
      <div className="relative mt-2 rounded-md shadow-sm">
        {props.icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <props.icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <input
          type="text"
          name={props.name}
          id={props.htmlId}
          disabled={props.disabled ? props.disabled : false}
          className={`block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900
          ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-indigo-600 sm:text-sm sm:leading-6 ${props.icon ? 'pl-10' : 'pl-7'}`}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Input;
