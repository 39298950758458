import React, { Dispatch, Fragment, ReactElement, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export interface ModalProps {
  isOpen: boolean;
  setOpen: Dispatch<boolean>;
  title: string;
  body: ReactElement;
  cancelText: string;
  cancelDisabled?: boolean;
  nextDisabled?: boolean;
  cancelAction: () => void;
  nextText?: string;
  nextAction?: () => void;
  closeOnNext?: boolean;
  icon?: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
  >;
}

const Modal = (props: ModalProps) => {
  const cancelButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={nextButtonRef}
        onClose={() => {
          props.setOpen;
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl
               transition-all sm:my-8 sm:w-full sm:max-w-xl"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {props.icon && (
                      <div
                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full
                    bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                      >
                        <props.icon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                      </div>
                    )}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {props.title}
                      </Dialog.Title>
                      <div className="mt-2">{props.body}</div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {props.nextText && (
                    <button
                      type="button"
                      disabled={props.nextDisabled}
                      ref={nextButtonRef}
                      className={
                        'inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm ' +
                        'font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:opacity-50'
                      }
                      onClick={() => {
                        if (props.closeOnNext) {
                          props.setOpen(false);
                        }
                        if (props.nextAction) {
                          props.nextAction();
                        }
                      }}
                    >
                      {props.nextText}
                    </button>
                  )}

                  {!props.cancelDisabled && (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm
                      font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50
                      sm:mt-0 sm:w-auto"
                      onClick={() => {
                        props.setOpen(false);
                        props.cancelAction();
                      }}
                      ref={cancelButtonRef}
                    >
                      {props.cancelText}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
