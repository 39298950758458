import { Routes, Route } from 'react-router-dom';
import Login from './login/login';
import TenantSelection from './tenantSelection/tenantSelection';
import React from 'react';
import LoginGuard from './login/guard';
import TenantManagement from './tenantManagement/TenantManagement';
import './app.css';
import SignOutPage from './login/signout';
import { JoinPage } from './join';

export const App = () => {
  return (
    <Routes>
      <Route
        path="/tenantSelection"
        element={
          <LoginGuard>
            <TenantSelection />
          </LoginGuard>
        }
      />

      <Route
        path="/t/:tenant/*"
        element={
          <LoginGuard>
            <TenantManagement />
          </LoginGuard>
        }
      />
      <Route
        path="/join"
        element={
          <LoginGuard>
            <JoinPage />
          </LoginGuard>
        }
      />

      <Route path="/signout" element={<SignOutPage />} />
      <Route path="/" element={<Login />} />
    </Routes>
  );
};
