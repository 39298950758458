import * as React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface TabProps {
  name: string;
  className?: string;
  activeClassName?: string;
  icon: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
  >;
}
export interface TabsProps {
  tabs: TabProps[];
  activeTabName: string;
  setActiveTabName: (name: string) => void;
}

export default function Tabs(props: TabsProps) {
  const currentTab = props.tabs.find((tab) => tab.name == props.activeTabName);
  if (currentTab === undefined) {
    console.error('could not determine current tab');
    return <p>Error rendering page</p>;
  }
  return (
    <div className={'pb-10'}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={currentTab.name}
        >
          {props.tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {props.tabs.map((tab) => (
              <button
                onClick={() => {
                  props.setActiveTabName(tab.name);
                }}
                key={tab.name}
                className={classNames(
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm',
                  tab.name == currentTab.name
                    ? 'border-indigo-500 text-indigo-600 ' +
                        (tab.activeClassName ? tab.activeClassName : '')
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 ' +
                        (tab.className ? tab.className : ''),
                )}
                aria-current={tab.name == currentTab.name ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.name == currentTab.name
                      ? 'text-indigo-500 ' + (tab.activeClassName ? tab.activeClassName : '')
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5 ' + (tab.className ? tab.className : ''),
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
