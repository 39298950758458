const EMPTY_COOKIE =
  'a1session=;path=/;domain=.management.stg.api1.app;expires=Thu, 01 Jan 1970 00:00:01 GMT';

const SignOutPage = () => {
  if (!process.env.REACT_APP_LOGOUT_URL) {
    throw new Error('expected env var REACT_APP_LOGOUT_URL');
  }
  document.cookie = EMPTY_COOKIE;
  localStorage.setItem('local_user_id', '');
  localStorage.setItem('local_user_email', '');
  window.location.replace(process.env.REACT_APP_LOGOUT_URL);
  return <p>Signing out...</p>;
};
export default SignOutPage;
