import Modal from '../components/modal';
import React, { ReactElement, useState } from 'react';
import Input from '../components/input';
import { usePatchTenantsByTenantIdMutation } from '../store/mgmtApi';

import { LightBulbIcon } from '@heroicons/react/24/outline';

interface InitializerProps {
  tenantId: string;
  userId: string;
}

interface modals {
  body: ReactElement;
  nextTitle: string;
  nextAction?: () => void;
  cancelAction?: () => void;
  nextDisabled?: boolean;
  cancelDisabled?: boolean;
}

const Initializer = (props: InitializerProps) => {
  const [bodyCtr, setBodyCtr] = useState(0);
  const [tenantNameValue, setTenantNameValue] = useState('');

  const [patchTenant] = usePatchTenantsByTenantIdMutation();
  const [open, setOpen] = useState(true);

  const modalBodies: modals[] = [
    {
      body: <p>You are new here, let's take 30 seconds to set up your tenant!</p>,
      nextTitle: 'Next',
      cancelAction: () => {
        patchTenant({
          tenantId: props.tenantId,
          patchTenant: {
            name: props.tenantId,
          },
        });
      },
    },
    {
      body: (
        <Input
          htmlId={'tenantName'}
          name={'tenantName'}
          value={tenantNameValue}
          onChange={(val) => {
            setTenantNameValue(val);
          }}
          placeholder={'My APIs'}
          title={'Give a friendly name to your tenant'}
        />
      ),
      nextTitle: 'Save & Next',
      nextDisabled: tenantNameValue === '',
      nextAction: () => {
        patchTenant({
          tenantId: props.tenantId,
          patchTenant: {
            name: tenantNameValue,
          },
        });
      },
    },
    {
      body: <p>All set! Find more tutorials and Get-Started guides here.</p>,
      nextTitle: 'Done',
      cancelDisabled: true,
    },
  ];

  const isLastBody = bodyCtr + 1 >= modalBodies.length;
  const modalBody = modalBodies[bodyCtr];

  return (
    <Modal
      closeOnNext={isLastBody}
      isOpen={open}
      setOpen={setOpen}
      title={'Welcome!'}
      cancelText={'Leave me alone'}
      cancelDisabled={modalBody.cancelDisabled}
      nextDisabled={modalBody.nextDisabled}
      icon={LightBulbIcon}
      cancelAction={() => {
        if (modalBody.cancelAction) {
          modalBody.cancelAction();
        }
      }}
      nextText={modalBody.nextTitle}
      nextAction={() => {
        if (modalBody.nextAction) {
          modalBody.nextAction();
        }
        if (!isLastBody) {
          setBodyCtr((prev) => prev + 1);
        }
      }}
      body={modalBody.body}
    />
  );
};

export default Initializer;
