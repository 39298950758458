import { Navigate } from 'react-router-dom';
import React from 'react';

const LoginGuard = ({ children }: { children: React.ReactElement }) => {
  const loginExists = isLoggedIn();
  if (!loginExists) {
    console.log('navigating to root for login');
    return <Navigate to="/" />;
  }

  return children;
};

const isLoggedIn = (): boolean => {
  const cookie = getSessionToken();
  return cookie !== '';
};
export const getSessionToken = () =>
  document.cookie.match('(^|;)\\s*' + 'a1session' + '\\s*=\\s*([^;]+)')?.pop() || '';

export default LoginGuard;
