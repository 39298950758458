import { LOAD_COLOR_BG } from '../../constants/color';

export enum BadgeColor {
  Grey,
  Red,
  Yellow,
  Green,
  Blue,
  Indigo,
  Purple,
  Pink,
}

const classesForColor = (
  color: BadgeColor,
): { bgClass: string; textClass: string; ringClass: string } => {
  switch (color) {
    case BadgeColor.Grey:
      return { bgClass: LOAD_COLOR_BG, textClass: 'text-gray-600', ringClass: 'ring-gray-50' };
    case BadgeColor.Red:
      return { bgClass: 'bg-red-50', textClass: 'text-red-700', ringClass: 'ring-red-600/10' };
    case BadgeColor.Yellow:
      return {
        bgClass: 'bg-yellow-50',
        textClass: 'text-yellow-800',
        ringClass: 'ring-yellow-600/20',
      };
    case BadgeColor.Green:
      return {
        bgClass: 'bg-green-50',
        textClass: 'text-green-700',
        ringClass: 'ring-green-600/20',
      };
    case BadgeColor.Blue:
      return { bgClass: 'bg-blue-50', textClass: 'text-blue-700', ringClass: 'ring-blue-700/10' };
    case BadgeColor.Indigo:
      return {
        bgClass: 'bg-indigo-50',
        textClass: 'text-indigo-700',
        ringClass: 'ring-indigo-700/10',
      };
    case BadgeColor.Purple:
      return {
        bgClass: 'bg-purple-50',
        textClass: 'text-purple-700',
        ringClass: 'ring-purple-700/10',
      };
    case BadgeColor.Pink:
      return { bgClass: 'bg-pink-50', textClass: 'text-pink-700', ringClass: 'ring-pink-700/10' };
  }
};

const Badge = (props: { color: BadgeColor; text?: string; expectedChars: number }) => {
  const text = props.text ? props.text : 'x'.repeat(props.expectedChars);
  const loading = props.text == null;
  const color = loading ? BadgeColor.Grey : props.color;
  const { bgClass, textClass, ringClass } = classesForColor(color);
  const loadingClass = loading ? 'animate-pulse hide-text' : '';
  return (
    <>
      <span
        className={`inline-flex items-center rounded-md ${bgClass} ${loadingClass} px-2 py-1 text-xs 
        font-medium ${textClass} ring-1 ring-inset ${ringClass}`}
      >
        {text}
      </span>
    </>
  );
};
export default Badge;
