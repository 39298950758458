import { emptyApi as api } from './emptyApi';
export const addTagTypes = [
  'auth',
  'permissions',
  'users',
  'tenants',
  'portals',
  'invites',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postAuth: build.mutation<PostAuthApiResponse, PostAuthApiArg>({
        query: () => ({ url: `/auth/`, method: 'POST' }),
        invalidatesTags: ['auth'],
      }),
      getAuthPermissions: build.query<GetAuthPermissionsApiResponse, GetAuthPermissionsApiArg>({
        query: () => ({ url: `/auth/permissions` }),
        providesTags: ['permissions'],
      }),
      getAuthPermissionsByTenantByTenantId: build.query<
        GetAuthPermissionsByTenantByTenantIdApiResponse,
        GetAuthPermissionsByTenantByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/auth/permissions/byTenant/${queryArg}` }),
        providesTags: ['permissions'],
      }),
      getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
        query: () => ({ url: `/users/me` }),
        providesTags: ['users'],
      }),
      getUsersByUserId: build.query<GetUsersByUserIdApiResponse, GetUsersByUserIdApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg}` }),
        providesTags: ['users'],
      }),
      patchUsersByUserIdPreferences: build.mutation<
        PatchUsersByUserIdPreferencesApiResponse,
        PatchUsersByUserIdPreferencesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.userId}/preferences`,
          method: 'PATCH',
          body: queryArg.userPreferences,
        }),
        invalidatesTags: ['users'],
      }),
      getTenantsByTenantId: build.query<
        GetTenantsByTenantIdApiResponse,
        GetTenantsByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/tenants/${queryArg}` }),
        providesTags: ['tenants'],
      }),
      patchTenantsByTenantId: build.mutation<
        PatchTenantsByTenantIdApiResponse,
        PatchTenantsByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}`,
          method: 'PATCH',
          body: queryArg.patchTenant,
        }),
        invalidatesTags: ['tenants'],
      }),
      getPortalsByTenantId: build.query<
        GetPortalsByTenantIdApiResponse,
        GetPortalsByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/portals/${queryArg}` }),
        providesTags: ['portals'],
      }),
      postInvitesByTenantId: build.mutation<
        PostInvitesByTenantIdApiResponse,
        PostInvitesByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/invites/${queryArg.tenantId}`,
          method: 'POST',
          body: queryArg.createInvite,
        }),
        invalidatesTags: ['invites'],
      }),
      deleteInvitesByTenantId: build.mutation<
        DeleteInvitesByTenantIdApiResponse,
        DeleteInvitesByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/invites/${queryArg.tenantId}`,
          method: 'DELETE',
          body: queryArg.deleteInvite,
        }),
        invalidatesTags: ['invites'],
      }),
      getInvitesByTenantId: build.query<
        GetInvitesByTenantIdApiResponse,
        GetInvitesByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/invites/${queryArg}` }),
        providesTags: ['invites'],
      }),
      postInvitesAccept: build.mutation<PostInvitesAcceptApiResponse, PostInvitesAcceptApiArg>({
        query: (queryArg) => ({
          url: `/invites/accept`,
          method: 'POST',
          body: queryArg.genericPostRequest,
          params: { token: queryArg.token },
        }),
        invalidatesTags: ['invites'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as mgmtApi };
export type PostAuthApiResponse = /** status 200 Permissions and session cookie */ Permissions;
export type PostAuthApiArg = void;
export type GetAuthPermissionsApiResponse = /** status 200 Permissions */ Permissions;
export type GetAuthPermissionsApiArg = void;
export type GetAuthPermissionsByTenantByTenantIdApiResponse =
  /** status 200 TenantPermissions */ TenantPermissions;
export type GetAuthPermissionsByTenantByTenantIdApiArg = /** tenant id */ string;
export type GetUsersMeApiResponse = /** status 200 Found user */ User;
export type GetUsersMeApiArg = void;
export type GetUsersByUserIdApiResponse = /** status 200 Found user */ User;
export type GetUsersByUserIdApiArg = /** userId */ string;
export type PatchUsersByUserIdPreferencesApiResponse =
  /** status 200 Updated user preferences */ GenericResponse;
export type PatchUsersByUserIdPreferencesApiArg = {
  /** user id */
  userId: string;
  userPreferences: UserPreferences;
};
export type GetTenantsByTenantIdApiResponse = /** status 200 Found tenant */ Tenant;
export type GetTenantsByTenantIdApiArg = /** tenantId */ string;
export type PatchTenantsByTenantIdApiResponse = /** status 200 Updated tenant */ GenericResponse;
export type PatchTenantsByTenantIdApiArg = {
  /** tenant id */
  tenantId: string;
  patchTenant: PatchTenant;
};
export type GetPortalsByTenantIdApiResponse = /** status 200 Found portal */ Portal;
export type GetPortalsByTenantIdApiArg = /** tenantId */ string;
export type PostInvitesByTenantIdApiResponse = /** status 200 Created invite */ GenericResponse;
export type PostInvitesByTenantIdApiArg = {
  /** tenantId */
  tenantId: string;
  createInvite: CreateInvite;
};
export type DeleteInvitesByTenantIdApiResponse = /** status 200 Deleted invite */ GenericResponse;
export type DeleteInvitesByTenantIdApiArg = {
  /** tenantId */
  tenantId: string;
  deleteInvite: DeleteInvite;
};
export type GetInvitesByTenantIdApiResponse = /** status 200 Found invites */ InvitesResponse;
export type GetInvitesByTenantIdApiArg = /** tenantId */ string;
export type PostInvitesAcceptApiResponse = /** status 200 Accepted invite */ GenericResponse;
export type PostInvitesAcceptApiArg = {
  token: string;
  genericPostRequest: GenericPostRequest;
};
export type Permissions = {
  permissions: {
    tenantId: string;
    role: string;
    userId: string;
  }[];
};
export type TenantPermissions = {
  permissions: {
    tenantId: string;
    role: string;
    userId: string;
    email: string;
  }[];
};
export type User = {
  id: string;
  email: string;
};
export type GenericResponse = {
  successful?: boolean;
};
export type UserPreferences = {
  tenantFriendlyNames?: {
    tenantId: string;
    friendlyName: string;
  }[];
};
export type Tenant = {
  id: string;
  name?: string;
};
export type PatchTenant = {
  id?: string;
  name?: string;
};
export type Portal = {
  host: string;
  customHost?: string;
};
export type CreateInvite = {
  email: string;
};
export type DeleteInvite = {
  id: string;
};
export type InvitesResponse = {
  invites?: {
    email: string;
    status: 'CREATED' | 'EXPIRED';
    created: string;
    id: string;
  }[];
};
export type GenericPostRequest = object;
export const {
  usePostAuthMutation,
  useGetAuthPermissionsQuery,
  useLazyGetAuthPermissionsQuery,
  useGetAuthPermissionsByTenantByTenantIdQuery,
  useLazyGetAuthPermissionsByTenantByTenantIdQuery,
  useGetUsersMeQuery,
  useLazyGetUsersMeQuery,
  useGetUsersByUserIdQuery,
  useLazyGetUsersByUserIdQuery,
  usePatchUsersByUserIdPreferencesMutation,
  useGetTenantsByTenantIdQuery,
  useLazyGetTenantsByTenantIdQuery,
  usePatchTenantsByTenantIdMutation,
  useGetPortalsByTenantIdQuery,
  useLazyGetPortalsByTenantIdQuery,
  usePostInvitesByTenantIdMutation,
  useDeleteInvitesByTenantIdMutation,
  useGetInvitesByTenantIdQuery,
  useLazyGetInvitesByTenantIdQuery,
  usePostInvitesAcceptMutation,
} = injectedRtkApi;
