import React, { useState } from 'react';
import Tabs, { TabProps } from '../../components/tabs';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import SubPageProps from '../props';
import GeneralSettings from './GeneralSettings';
import MembersSettings from './MembersSettings';
import {
  useGetAuthPermissionsByTenantByTenantIdQuery,
  useGetTenantsByTenantIdQuery,
} from '../../store/mgmtApi';
import DangerZoneSettings from './DangerZoneSettings';

const tabs: TabProps[] = [
  {
    name: 'General',
    icon: InformationCircleIcon,
  },
  {
    name: 'Members',
    icon: UsersIcon,
  },
  {
    name: 'Danger Zone',
    icon: ExclamationTriangleIcon,
    className:
      'text-red-500 hover:text-orange-700 group-hover:text-orange-700 hover:border-orange-300',
    activeClassName: 'border-red-500 text-red-600',
  },
];

const SettingsPage = (props: SubPageProps) => {
  const [activeTabName, setActiveTabName] = useState('General');
  useGetTenantsByTenantIdQuery(props.tenant.id);
  useGetAuthPermissionsByTenantByTenantIdQuery(props.tenant.id);

  return (
    <>
      <Tabs tabs={tabs} activeTabName={activeTabName} setActiveTabName={setActiveTabName} />
      {activeTabName == 'General' && <GeneralSettings tenant={props.tenant} user={props.user} />}
      {activeTabName == 'Members' && <MembersSettings tenant={props.tenant} user={props.user} />}
      {activeTabName == 'Danger Zone' && (
        <DangerZoneSettings tenant={props.tenant} user={props.user} />
      )}
    </>
  );
};

export default SettingsPage;
