import React, { useState } from 'react';
import StackedTwoColumnsList, { Item, ItemStatus } from '../../components/stackedTwoColumnsList';
import Modal from '../../components/modal';
import ActionGridList, {
  ActionGridListItem,
  ActionGridListItemColor,
} from '../../components/actionGridList';
import {
  BeakerIcon,
  CloudIcon,
  PuzzlePieceIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';

const getStatus = (apiStatus: string): ItemStatus | undefined => {
  switch (apiStatus) {
    case 'Ready':
      return { className: 'bg-emerald-500', className20: 'bg-emerald-500/20', title: 'Running' };
    case 'Creating':
      return { className: 'bg-blue-500', className20: 'bg-blue-500/20', title: 'Creating' };
  }
  return undefined;
};

const ApisPage = () => {
  const apis: { name: string; type: string; id: string; status: string }[] = [];

  const [createModalOpen, setCreateModalOpen] = useState(false);

  let items: null | Item[] = null;
  if (apis) {
    items = apis.map((api, idx) => {
      const item: Item = {
        main: api.name,
        subMainPrimary: api.type,
        subMainSecondary: api.id,
        href: `${api.id}`,
        status: getStatus(api.status),
        id: idx,
      };
      return item;
    });
  }

  const createApiActions: ActionGridListItem[] = [
    {
      title: 'Playground',
      href: '../playground-apis/create',
      iconColor: ActionGridListItemColor.Teal,
      icon: BeakerIcon,
      text:
        'Fastest way to get started, no own code needed! We will deploy a sample API for you, so you ' +
        'can immediately start testing our features. ',
      externalLink: false,
    },
    {
      title: 'Cloud',
      href: '../cloud-apis/create',
      iconColor: ActionGridListItemColor.Sky,
      icon: CloudIcon,
      text:
        'Easiest way to connect your existing application. No need to deploy anything, we will manage ' +
        'your API for you that enables user to connect to your application, wherever it runs.',
      externalLink: false,
    },
    {
      title: 'Integrated',
      href: '../integrated-apis/create',
      iconColor: ActionGridListItemColor.Purple,
      icon: PuzzlePieceIcon,
      text:
        'Deploy our managed API Gateway in your stack. You can still manage all features via our Management ' +
        'Board, with the added benefit that our managed API Gateway will run alongside your application.',
      externalLink: false,
    },
    {
      title: 'Dont know which to chose?',
      href: '/guides/api-types',
      iconColor: ActionGridListItemColor.Grey,
      icon: QuestionMarkCircleIcon,
      text: 'Learn more about each type and which suits your business-needs best in our APIs Guide',
      externalLink: true,
    },
  ];

  return (
    <>
      <div className="sm:w-full lg:w-3/4">
        <div className=" px-4 text-sm mb-7 mt-2 mr-20 text-gray-700">
          <p>
            Setup an API that connects to your application and and benefit from ready-to-use
            monitoring, authentication, rate-limiting, and lots of other features.
          </p>
        </div>
        <StackedTwoColumnsList
          items={items}
          mainAction={{
            actionFunc: () => {
              setCreateModalOpen(true);
            },
            title: 'Create API',
          }}
        />
      </div>
      <Modal
        isOpen={createModalOpen}
        setOpen={setCreateModalOpen}
        title="Select API Type"
        body={<ActionGridList items={createApiActions} />}
        cancelText={'Cancel'}
        nextDisabled
        cancelAction={() => {
          setCreateModalOpen(false);
        }}
      />
    </>
  );
};

export default ApisPage;
