import { Switch } from '@headlessui/react';

export interface TwoColumnFormBoxes {
  formType: 'checkboxes';
  title: string;
  boxes: {
    title: string;
    text?: string;
    id: string;
    name: string;
    checked: (value: string) => void;
  }[];
}
export interface TwoColumnFormSelect {
  formType: 'select';
  id: string;
  name: string;
  label: string;
  options: string[];
  select: (value: string) => void;
}
export interface TwoColumnFormInput {
  formType: 'input';
  type: string;
  name: string;
  id: string;
  autoComplete?: string;
  label: string;
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
}

export interface TwoColumnFormToggle {
  formType: 'toggle';
  enabled: boolean;
  label: string;
  text: string;
  toggle: (value: boolean) => void;
}

const getFormElement = (
  input: TwoColumnFormInput | TwoColumnFormSelect | TwoColumnFormBoxes | TwoColumnFormToggle,
  disabled?: boolean,
) => {
  if (input.formType == 'input') {
    return (
      <div className="sm:col-span-3">
        <label htmlFor={input.name} className="block text-sm font-medium leading-6 text-gray-900">
          {input.label}
        </label>
        <div className="mt-2">
          <input
            disabled={disabled}
            type={input.type}
            name={input.name}
            id={input.id}
            autoComplete={input.autoComplete}
            placeholder={input.placeholder}
            value={input.value}
            onChange={(event) => {
              input.setValue(event.target.value);
            }}
            className={
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ' +
              'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ' +
              'sm:text-sm sm:leading-6'
            }
          />
        </div>
      </div>
    );
  }
  if (input.formType == 'select') {
    return (
      <div className="sm:col-span-3">
        <label htmlFor={input.name} className="block text-sm font-medium leading-6 text-gray-900">
          {input.label}
        </label>
        <div className="mt-2">
          <select
            disabled={disabled}
            id={input.id}
            name={input.name}
            onChange={(event) => {
              input.select(event.target.value);
            }}
            className={
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ' +
              'ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm ' +
              'sm:leading-6'
            }
          >
            {input.options.map((opt) => (
              <option>{opt}</option>
            ))}
          </select>
        </div>
      </div>
    );
  }
  if (input.formType == 'checkboxes') {
    return (
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">{input.title}</legend>
        <div className="mt-6 space-y-6">
          {input.boxes.map((box) => (
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={disabled}
                  id={box.id}
                  name={box.name}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor={box.name} className="font-medium text-gray-900">
                  {box.title}
                </label>
                <p className="text-gray-500">{box.text}</p>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    );
  }
  if (input.formType == 'toggle') {
    return (
      <Switch.Group as="div" className="flex items-center justify-between">
        <span className="flex flex-grow flex-col">
          <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
            {input.label}
          </Switch.Label>
          <Switch.Description as="span" className="text-sm text-gray-500">
            {input.text}
          </Switch.Description>
        </span>
        <Switch
          disabled={disabled}
          checked={input.enabled}
          onChange={(enabled) => {
            input.toggle(enabled);
          }}
          className={classNames(
            input.enabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 ' +
              'border-transparent transition-colors duration-200 ease-in-out focus:outline-none ' +
              'focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              input.enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ' +
                'ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </Switch.Group>
    );
  }
};

export interface TwoColumnFormSection {
  title: string;
  subText: string;
  inputs: (TwoColumnFormInput | TwoColumnFormSelect | TwoColumnFormBoxes | TwoColumnFormToggle)[];
}
export interface TwoColumnFormProps {
  sections: TwoColumnFormSection[];
  saveText?: string;
  cancelText?: string;
  saveAction: () => void;
  cancelAction: () => void;
  cancelDisabled?: boolean;
  saveDisabled?: boolean;
  disableAll?: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function TwoColumnForm(props: TwoColumnFormProps) {
  return (
    <form>
      <div className="space-y-12">
        {props.sections.map((section) => {
          return (
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">{section.title}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">{section.subText}</p>
              </div>
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                {section.inputs.map((input) => {
                  return (
                    <div className="sm:col-span-4">{getFormElement(input, props.disableAll)}</div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          disabled={props.cancelDisabled || props.disableAll}
          onClick={() => {
            props.cancelAction();
          }}
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm
                      font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50
                      sm:mt-0 sm:w-auto"
        >
          {props.cancelText ? props.cancelText : 'Cancel'}
        </button>
        <button
          disabled={props.saveDisabled || props.disableAll}
          type="button"
          onClick={() => {
            props.saveAction();
          }}
          className={
            'inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm ' +
            'font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:opacity-50'
          }
        >
          {props.saveText ? props.saveText : 'Save'}
        </button>
      </div>
    </form>
  );
}
