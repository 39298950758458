import React, { useEffect, useState } from 'react';
import TwoColumnForm, { TwoColumnFormSection } from '../../../../components/twoColumnForm';
import { useNavigate } from 'react-router-dom';
import Notification, { NotificationStatus } from '../../../../components/notification';

export const PlaygroundAPICreation = () => {
  const navigate = useNavigate();

  const [showNotif, setShowNotif] = useState(false);

  const [apiName, setApiName] = useState('');
  const [authReq, setAuthReq] = useState(true);
  const [syncUsers, setSyncUsers] = useState(false);

  const generalSection: TwoColumnFormSection = {
    title: 'General',
    subText: '',
    inputs: [
      {
        formType: 'input',
        id: 'input-apiname',
        name: 'apiname',
        type: 'text',
        value: apiName,
        placeholder: 'API Name',
        label: 'Name',
        setValue: setApiName,
      },
    ],
  };

  const authSection: TwoColumnFormSection = {
    title: 'Authentication',
    subText: '',
    inputs: [
      {
        formType: 'toggle',
        label: authReq ? 'Authentication required' : 'No authentication required',
        enabled: authReq,
        toggle: setAuthReq,
        text: authReq
          ? 'The API can only be called by authenticated users'
          : 'The API can be called by anyone.',
      },
    ],
  };
  if (authReq) {
    authSection.inputs.push({
      formType: 'toggle',
      label: syncUsers ? 'Sync users' : "Don't sync users",
      enabled: syncUsers,
      toggle: setSyncUsers,
      text: syncUsers
        ? 'Sync users from your user base'
        : 'Users are set up manually by administrators',
    });
  }

  function createApi() {
    console.log(`create api ${apiName}`);
  }

  function cancel() {
    navigate('../apis');
  }
  const isSuccess = true;
  const isError = false;
  const isLoading = false;

  useEffect(() => {
    if (isSuccess || isError) {
      setShowNotif(true);
    }
  }, [isError, isSuccess]);

  return (
    <>
      <Notification
        show={showNotif}
        setShow={setShowNotif}
        title={isSuccess ? 'API created' : 'Error creating APi'}
        text={isSuccess ? '' : 'Try again later'}
        status={isSuccess ? NotificationStatus.Success : NotificationStatus.Error}
        time={isSuccess ? 2000 : 20000}
      />
      <div className="pb-12">
        <p className="text-sm text-gray-700">
          Set your APIs basic configuration. Don't worry, you can change any of them later on.
        </p>
      </div>
      <TwoColumnForm
        disableAll={isLoading}
        sections={[generalSection, authSection]}
        saveAction={createApi}
        cancelAction={cancel}
        saveText={'Create API'}
        saveDisabled={apiName.length < 3}
      />
      <p className="text-xs text-gray-700 w-full text-right mt-4">
        Your API will be created in closed state, meaning your API will not be reachable until you
        explicitly set it to 'live' in the API view.
      </p>
    </>
  );
};
