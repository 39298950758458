import * as React from 'react';
import { Link } from 'react-router-dom';

export enum ActionGridListItemColor {
  Indigo,
  Rose,
  Yellow,
  Sky,
  Purple,
  Teal,
  Grey,
}
const colorClasses = (color: ActionGridListItemColor) => {
  switch (color) {
    case ActionGridListItemColor.Indigo:
      return 'text-indigo-700 bg-indigo-50';
    case ActionGridListItemColor.Rose:
      return 'text-rose-700 bg-rose-50';
    case ActionGridListItemColor.Yellow:
      return 'text-yellow-700 bg-yellow-50';
    case ActionGridListItemColor.Sky:
      return 'text-sky-700 bg-sky-50';
    case ActionGridListItemColor.Purple:
      return 'text-purple-700 bg-purple-50';
    case ActionGridListItemColor.Teal:
      return 'text-teal-700 bg-teal-50';
    case ActionGridListItemColor.Grey:
      return 'text-grey-700 bg-grey-50';
  }
};
export interface ActionGridListItem {
  title: string;
  href: string;
  externalLink: boolean;
  text: string;
  iconColor: ActionGridListItemColor;
  icon: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
  >;
}
export interface ActionGridListProps {
  items: ActionGridListItem[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function ActionGridList(props: ActionGridListProps) {
  return (
    <div
      className={
        'divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 ' +
        'gap-4 sm:divide-y-0'
      }
    >
      {props.items.map((item) => (
        <div
          key={item.title}
          className={
            `ring-1 ring-inset ring-gray-300 rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ` +
            `group relative bg-white p-6 ` +
            `${
              item.externalLink
                ? 'hover:ring-2 hover:ring-gray-500'
                : 'hover:ring-2 hover:ring-indigo-500'
            }`
          }
        >
          <div>
            <span
              className={classNames(
                colorClasses(item.iconColor),
                'inline-flex rounded-lg p-3 ring-4 ring-white',
              )}
            >
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Link
                to={item.href}
                target={item.externalLink ? '_blank' : undefined}
                className="focus:outline-none"
              >
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {item.title}
              </Link>
            </h3>
            <p className="mt-2 text-sm text-gray-500">{item.text}</p>
          </div>
          {item.externalLink && (
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d={
                    'M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 ' +
                    '101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 ' +
                    '1.414 1.414 16-16-1.414-1.414z'
                  }
                />
              </svg>
            </span>
          )}
        </div>
      ))}
    </div>
  );
}
